
.feedback .toast-header{
    background-color: #eeeeee;
}

.feedback .btn-close{
    margin-right: 0px;
    border: 1px solid #333;
    width: 20px;
    height: 12px;
    border-radius: 25px;
    background: url(/src/logo/close.png);
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center;
    --bs-btn-close-bg: url();
}

.toast-body .modal-button{
    background-color: #333;
    color: #fff;
}

.toast-body .modal-button:hover{
    background-color: #222;
    color: #fff;
}

.toast-body .form-check-input{
    border-width: 2px;
}

.toast-body #inlineRadio1{
    border-color: rgb(23 160 134) !important;
}

.toast-body #inlineRadio2{
    border-color: rgb(23 84 160) !important;
}

.toast-body #inlineRadio3{
    border-color: rgb(82 88 96) !important;
}

.toast-body #inlineRadio4{
    border-color: rgb(218 121 33) !important;
}

.toast-body #inlineRadio5{
    border-color: rgb(196, 46, 46) !important;
}

.toast-body #inlineRadio1:checked[type=radio]{
    background-color: rgb(23 160 134) !important;
}

.toast-body #inlineRadio2:checked[type=radio]{
    background-color: rgb(23 84 160) !important;
}

.toast-body #inlineRadio3:checked[type=radio]{
    background-color: rgb(82 88 96) !important;
}

.toast-body #inlineRadio4:checked[type=radio]{
    background-color: rgb(218 121 33) !important;
}

.toast-body #inlineRadio5:checked[type=radio]{
    background-color: rgb(23 160 134) !important;
}

.modal-button {
    margin-left: 250px;
}