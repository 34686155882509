/* App Common style */


.row.clear-margin {
  margin: 0 !important;
}

nav.navbar {
  padding: 0;
  margin-bottom: 0px;
}

nav .container-fluid {
  margin: 0;
  padding: 0;
}

nav .navbar-brand {
  padding: 0;
}

.navbar-brand img {
  height: 60px;
}

.logged-user {
  margin-right: 20px;
}

.nav-bar-key {
  font-size: 16px;
}

main.container-fluid {
  margin: 0;
  padding: 0;
}

div.row .main-menu {
  padding-left: 0;
}


.menu-item-list {
  margin: 0;
  padding: 0;
}

div.selected-menu-item {
  height: 40px;
  background-color: #e8e8e8;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

.project-list {
  padding: 10px 20px;
  font-size: 15px;
  margin: 0 0 0 20px;
 
}

.project-list label {
  font-size: 15px;
  border-bottom: 1px solid #ccc;
  display: block;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-weight: 500;
}

.project-list select {
  border: none;
}

.from-select-sm {
  font-weight: bold;
}
.list-group .menu-item-list li {
  padding: 0 !important;
  margin: 0;
  font-size: 13px;
  border-bottom: 1px solid #e8e8e8;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 40px;
  background-color: #fff !important;
  border-right: 1px solid #e8e8e8;
  transition: all 0.3 ease;
}

.list-group .menu-item-list li::after {
  content: "";
  background-color: #ddd;
  width: 10px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 15px;
  background: url(logo/right-arrow.png);
  background-size: cover;
}

.list-group .menu-item-list li:first-child {
  border-top: 1px solid #e8e8e8;
}

.list-group .menu-item-list li.active {
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  border-color: #fff;
}

.list-group .menu-item-list li.active:hover {
  background-color: #0b0808  !important;
  cursor: default;
}

.list-group .menu-item-list li:hover {
  background-color: #0b0808 !important;
  border-color: #333;
  cursor: pointer;
  transition: all 0.3 ease;
  color: rgb(236, 231, 231);
}

.menu-item-list div{
  display: inline-block;
    margin-right: 20px;
    background-color: #e8e8e8;
    text-align: center;
    padding-top: 8px;
    height: 39px;
    width: 39px;
}

.menu-item-list img {
  width: 20px;
  height: 20px;
  border: none;
}


/* Detail view styles---------- */

.detail-block {
  margin: 40px;
}

.detail-header {
  margin-bottom: 20px;
}

.detail-header .header-text {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
}

.form-block {
  font-size: 12px;
}

.detail-info-block {
  font-size: 12px;
  color: #888888;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.detail-info-block p {
  margin: 0;
  font-weight: 600;
}

.button-with-model {
  display: inline-block;
  margin-left: 20px;
}

.button-with-model button {
  font-size: 13px;
  font-weight: 400;
  border-radius: 6px;
  border: 2px solid #333;
  width:140px;
  text-align:center
}

.align-right {
  text-align: right;
}

.select-all {
  font-size: 14px;
}

.action-table {
  font-size: 14px;
}

.action-table table {
  width: 100%;
}

.action-table table tr th:first-child,
.action-table table tr td:first-child {
  width: 40%;
}

.action-table table tr th {
  border-bottom: 1px solid #333;
 
}

.action-table table tr td input {
  margin: 6px 20px 0 0px;
 
}

.action-table table tr td button {
  margin: 0px 20px 0 0px;
  color: #fff;
  border: 2px solid #ccc;
  padding: 0px;
  height: 24px;
  width: 24px;
  font-size: 13px;
  background-color: rgb(59, 59, 59);
  border-radius: 15px;
}

.action-table table tr td button:hover {
  background-color: #222;
  color: #fff;
  border: 2px solid #ccc;
}

.action-table table tr td select {
  height: 30px;
  margin: -2px 0;
  border: 1px solid #ccc;
  width: 60%;
}

/*manage delegate table*/

.md-table {
  font-size: 14px;
}

.md-table table {
  width: 100%;
}

.md-table table tr th:first-child,
.md-table table tr td:first-child {
  width: 50%;
}

.md-table table tr th {
  border-bottom: 1px solid #333;
 
}

.md-table table tr td input {
  margin: 6px 20px 0 0px;
 
}


.md-table table tr td button {
  margin: 0px 20px 0 0px;
  color: #fff;
  border: 2px solid #ccc;
  padding: 0px;
  height: 24px;
  width: 24px;
  font-size: 13px;
  background-color: rgb(59, 59, 59);
  border-radius: 15px;
}

.md-table table tr td button:hover {
  background-color: #222;
  color: #fff;
  border: 2px solid #ccc;
}

.md-table table tr td select {
  height: 30px;
  margin: -2px 0;
  border: 1px solid #ccc;
  width: 100%;
}

/* Popup Styling------ */

div.modal-width {
  width: 720px;
  max-width: 720px;
  font-size: 13px;
}

div.modal-header {
  font-size: 15px;
  font-weight: 500;
  background-color: #222;
  color: #fff;
  padding: 10px 20px;
}

div.errorModel {
  font-size: 15px;
  font-weight: 500;
  background-color: #000000;
  color: #fff;
  padding: 10px 20px;
}

.modal-header .btn-close{
  background-color: #fff;
    width: 8px;
    background-size: 12px;
    height: 8px;
    opacity: 1;
}



div.modal-title {
  margin: 10px 0 0 0;
  font-weight: 500;
}

div.modal-title .col-2{
  text-align: center;
}

div.modal-body{
  text-align: left;
}

div.shift-list {
  width: 320px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: unset;
  display: inline-block;

}

.list-action-set{
  width: 60px;
}

.list-user-set{
  /* width: 80%; */
  min-width: 100px;
  width: 220px;
}

.list-action-set, .list-user-set{
  display: inline-block;
  vertical-align: middle;
}

.list-btn {
  margin: 0px;
}

div.shift-list-action {
 
  width: 60px;
  display: inline-block;
  padding: 10px 0;
  margin: 40px 0 0 0;

}

.shift-list-block {
  padding: 0px;
}

.shift-list div {
  border: 1px solid #ccc;
  margin: 10px;
}

.shift-list div ul {
  list-style: none;
}

.shift-list div ul li:nth-child(odd) button {
  background-color: #eee;
}

.shift-list div ul li button {
  border: none;
}
.shift-list div ul li button span{
  content: "";
  /* background-color: #ddd; */
  width: 25px;
  height: 25px;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius:10%;
  text-align: center;
  /* background: url(logo/right-arrow.png); */
  background-size: cover;
}
.shift-list div ul li button span:hover{
  content: "";
  
  background-color: #e4eae9;
  border-color: #989c9c !important;
  
}
.left-arrow{
  content: "";
  /* background-color: #ddd; */
  width: 25px;
  height: 25px;
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius:10%;
  text-align: center;
  /* background: url(logo/right-arrow.png); */
  background-size: cover;
}
.left-arrow:hover{
  content: "";
  
  background-color: #e4eae9;
  border-color: #989c9c !important;
  
}
.shift-list div ul li button label{
  content: "";
  text-align: center;
  margin-left: 20px;
  top: 15px;
}

.left-arrow{
  /* content: ""; */
  /* background-color: #ddd; */
  width: 25px;
  height: 20px;
  position: absolute;
  /* left: 5px;
  top: 15px; */
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius:10%;
  text-align: center;
  /* background: url(logo/right-arrow.png); */
  background-size: cover;
  margin-right: 10px;
  text-align: center;
}
.left-arrow:hover{
  /* content: ""; */
  
  background-color: #e4eae9;
  border-color: #989c9c !important;
  
}

.shift-list-action button {
  font-size: 11px;
  width: 100%;
  padding: 7px 0;
  margin: 10px 0;
  border-radius: 3px;
  border: 2px solid #4972bd !important;
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  color: #fff;
  font-weight: 500;
}

.shift-list-action button:hover{
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  color: #fff;
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

button.modal-button{
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid linear-gradient(90deg, #403fe3, #497ab5) !important;
  background: #333;
  color: #FFF
}

button.modal-button:hover{
  border: 1px solid #333;
  color: rgb(244, 232, 232);
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
}


/* OLD Styles--------- */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.form-block {
  font-size: 16px;
}
.delegateeList{
  font-size: 12px !important;
  max-height: 40px;
  overflow-y: scroll;
  margin-left: 3px;
}

.form-check-label { 
  font-size: 13px;
}

.form-check-input:checked {
  background-color: #92959b !important;
  border-color: #898e95 !important;
}

.form-check-label-input {
  font-weight: 600;
  color: rgb(79, 70, 70);
  font-size: 12.5px;
}

.nav-link dropdown-toggle{
  font-size: 12px;
}

.notify {
  text-align: center;
  margin: 40px auto;
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  width: 142px;
  color: #fff;
  border: 2px solid #497ab5;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
 
}

.listButtonRight span{
  float: right;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 5px solid #e8e8e8; 
  border-top: 5px solid#f3f3f3; 
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.remove-all-btn{
  font-size: 13px;
  font-weight: 400;
  border-radius: 6px;
  border: 2px solid #dc3545!important;
  width: 140px;
  text-align: center;
  background-color: #dc3545;
  color: #fff;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.remove-all-btn:hover{
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.bulk-config-btn{
  font-size: 13px;
  font-weight: 400;
  border-radius: 6px;
  border: 2px solid #4972bd !important;
  width: 140px;
  text-align: center;
  background-color: #4972bd;
  color: #fff;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.bulk-config-btn:hover{
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.popup-btn{
  font-size: 13px;
  font-weight: 400;
  border-radius: 6px;
  border: 2px solid #333!important;
  width: 140px;
  text-align: center;
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.popup-btn:hover{
  background: linear-gradient(0deg,#403fe2,#4a7bb6)!important;
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  border: none !important;

}

/*Banner CSS*/

.ch-notif-banner .modal.show .modal-dialog{
  width: 460px;
}

.ch-notif-banner .modal-content{
  background-color: transparent;
}

.ch-notif-banner .bannermodal-body{
  border-radius: 12px;
  overflow: hidden;
}

.ch-notif-banner img{
  width: 100%;
}

.ch-notif-banner .btn-close{
  right: 0;
  border: 1px solid #fff;
  margin-top: -20px;
  background-color: #fff;
  padding: 7px;
  border-radius: 20px;
  margin-right: -20px;
  position:  absolute;
}



.banner-content{
  padding: 0px;
  max-width: fit-content;
}

.banner.modal-title{
  margin: 0px;
}


.center_nf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fff;
  padding-bottom: 20%;
}


.center_nf .f04 {
  color: rgb(8, 27, 75);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.2;
  margin: 0px;
  text-align: center;
  margin-bottom: 2%;
}
.center_nf .pnf {
  margin-top: 2%;
  font-size: 15px;
  color: #333;
  justify-content: center;
  align-items: center;
  display: flex;

}
.center_nf .pnf a{
  width: 100px;
  height: 40px;
  /* margin-right: 20px; */
  font-size: 18px;
  color: rgb(8, 27, 75);
  font-weight: normal;
  /* margin-bottom: 5px; */
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none !important;
  border: 2px solid rgb(8, 27, 75);
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  text-align: center;
  
}
.center_nf .pnf a:hover{
  background-color: rgb(8, 27, 75);
  color: #f8f8f8;
}
.inline-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}