.container {
  text-align: center;
}
div.toggle-switch{
  position: relative;
  width: 60px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  font-size: 12px;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 25px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #4972bd;
  color: #fff;
}
.inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: rgb(207 106 106);
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 21px;
  height: 21px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 35px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
.disabled {
  background-color: #ddd;
  color: rgb(86, 80, 80);
  cursor: not-allowed;
}
.disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.checkbox:disabled + .label .inner::before,.checkbox:disabled + .label .inner::after{background-color: #ccc;}